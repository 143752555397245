.crs-table-container {
  .crs-table {
    .crs-table-header {
      .crs-table-row {
        .crs-table-cell {
          padding: 8px;
        }
      }
    }

    .crs-table-body {
      .crs-table-row {
        .crs-table-cell {
          padding: 2px 8px;

          p {
            margin: 0 5px;
          }

          .icon-button {
            width: 25px;
            height: 25px;
          }

          &.has-table {
            padding: 0 !important;
            border: none;
          }
        }

        &:hover {
          background-color: $grayedOpacity;
        }
      }
    }

    .no-data-row {
      td {
        text-align: center;
      }
    }

    .table-actions {
      display: flex;
      justify-content: space-around;
    }
  }
}

.react-admin-table {
  tr {
    td {
      .crs-table-container {
        .crs-table {
          .crs-table-body {
            .crs-table-row {
              .crs-table-cell {
                padding: 8px;

                .table-actions {
                  display: flex;
                  justify-content: space-around;

                  .icon {
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
