$fontComfortaaRegular: 'ComfortaRegular';
$fontComfortaaLight: 'ComfortaLight';
$fontComfortaaBold: 'ComfortaBold';

$mobileScreen: 768px;
$tabletScreen: 1024px;

$darkBlue: #00053b;
$darkPurple: #384ca1;
$lightPurple: #8f98ff;
$ghostWhite: #f8f8ff;
$saturatedShade: #00053b;
$black: #000000;
$gray: #d0d0d0;
$gainsboro: #e2e2e2;
$lavender: #e8eaff;
$white: #ffffff;
$blueberry: #747fff;
$titanWhite: #fbfbff;
$coconut: #fefefe;
$green: #00c852;
$red: #e90000;
$lightRed: #f44336;
$orange: #ff8a00;
$blue: #464ec1;
$grayedOpacity: rgba(0, 0, 0, 0.04);
$primary: rgba(63, 81, 181, 0.5);
$pink: #df024d;
$whisper: rgba(0, 0, 0, 0.09);
$whisperHover: rgba(0, 0, 0, 0.13);
