.create-bundle,
.edit-bundle {
  height: 100%;

  .create-bundle-form,
  .edit-bundle-form {
    width: 100%;
    min-height: 100%;
    height: auto;

    .bundle-header {
      display: flex;
      align-items: center;
      min-height: 68px;

      .icon-wrapper {
        margin-left: 20px;

        input {
          display: none;
        }

        .MuiIconButton-root {
          padding: 0;

          .icon-upload-label {
            cursor: pointer;
            width: fit-content;

            .bundle-icon {
              height: 60px;
              width: 60px;
              border-radius: 50%;
              object-fit: cover;
            }

            .default-icon {
              vertical-align: middle !important;
            }
          }
        }
      }
    }

    .divider {
      margin: 15px 0;

      &.secondary {
        margin: 10px 0;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .is-public-switcher {
        width: max-content;
        margin-top: 10px;
      }

      .text-field {
        max-width: 500px;

        + .text-field {
          margin-top: 15px;
        }
      }

      .actions {
        margin-top: 15px;
      }

      .crs-uploader {
        max-width: 500px;
        margin-top: 15px;
      }

      .add-service-button {
        margin: 0 15px;
        width: 40px;
        height: 40px;
        display: flex;
        align-self: flex-end;
        border: 1px solid $primary;

        svg {
          font-size: 22px;
        }
      }
    }

    .card {
      width: 100%;
      margin-top: 15px;

      .card-actions {
        display: flex;
        justify-content: space-between;

        .cancel-button {
          margin-left: 15px;
        }

        .delete {
          background-color: $pink;
        }
      }
    }
  }
}

.bundle-services {
  display: flex;
  align-items: center;
}
