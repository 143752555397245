.edit-branch {
  height: 100%;

  .edit-branch-title {
    padding-left: 16px !important;
  }

  .divider {
    margin: 15px 0;
  }

  .edit-page {
    height: calc(100% - 64px);

    div {
      height: 100%;

      div {
        height: 100%;

        .simple-form {
          display: flex;
          flex-direction: column;
          min-height: 100%;
          height: auto;

          .MuiCardContent-root {
            flex-grow: 1;
          }

          .MuiToolbar-root {
            .cancel-button {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

.create-branch,
.edit-branch {
  height: 100%;

  .create-branch-form,
  .edit-branch-form {
    width: 100%;
    min-height: 100%;
    height: auto;

    .divider {
      margin: 15px 0;

      &.secondary {
        margin: 10px 0;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .text-field {
        max-width: 500px;

        + .text-field {
          margin-top: 15px;
        }
      }

      .actions {
        margin-top: 15px;
      }
    }

    .card {
      width: 100%;
      margin-top: 15px;

      .card-actions {
        display: flex;
        justify-content: space-between;

        .cancel-button {
          margin-left: 15px;
        }

        .delete {
          background-color: $pink;
        }
      }
    }
  }
}
