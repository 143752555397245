.crs-uploader {
  padding: 15px;

  .dropzone {
    display: inline;

    .upload-button {
      color: $blue;
      background-color: $white;

      &:hover {
        color: $white;
        background-color: $blue;
      }
    }
  }

  .save-button {
    margin-left: 15px;
    color: $white;
    background-color: $blue;

    &:hover {
      color: $blue;
      background-color: $white;
    }
  }

  .uploaded-files {
    display: flex;
    flex-direction: column;

    .uploaded-file {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon {
        color: $red;
      }
    }
  }
}
