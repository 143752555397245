.create-user {
  width: 80%;
  margin: 5px auto;
  height: 100%;

  .create-user-form {
    width: 100%;
    min-height: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    .divider {
      margin: 15px 0;

      &.secondary {
        margin: 10px 0;
      }
    }
    .create-user-data-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 100px;
      flex-grow: 1;

      .create-user-data {
        display: flex;
        flex-direction: column;
        width: 500px;

        .MuiFormControl-root {
          margin-bottom: 15px !important;

          .MuiInput-formControl {
            margin-top: 16px !important;
          }
        }
      }
    }

    .card {
      width: 100%;
      margin-top: 15px;
      .card-actions {
        display: flex;
        justify-content: space-between;

        .cancel-user-button {
          margin-left: 15px;
        }
      }
    }
  }
}

.crc-create-user-dialog {
  .crs-dialog-paper {
    width: 40%;
    max-width: unset;
  }

  .crs-dialog-content {
    height: 150px;
    overflow: hidden;
    border: none;

    .MuiFormControl-root {
      margin-bottom: 20px !important;
      width: 350px;
    }

    .text-red {
      color:red;
    }
  }

  .crs-dialog-title {
    padding: 10px 30px;
  }

  .submit-user-button {
    margin-right: 20px !important;
    width: 80px;
  }
}
