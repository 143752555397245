.crc-patient-dialog {
  .crs-dialog-paper {
    width: 35%;
    max-width: unset;
  }

  .crs-dialog-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;

    .uploader-container {
      display: flex;
      align-items: center;
      .profile-image-upload-label {
        display: flex;
        width: fit-content;
        cursor: pointer;
      }
      .profile-picture {
        height: 150px;
        width: 150px;
        border-radius: 50%;
        object-fit: cover;
      }
      #patientImageUploader {
        display: none;
      }
    }
  }
  .crs-dialog-actions {
    .cancel-button,
    .add-button {
      width: 100px;
    }
  }
}
