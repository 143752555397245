.create-notification-template,
.edit-notification-template {
  width: 100%;
  height: 100%;

  .create-notification-template-form,
  .edit-notification-template-form {
    width: 100%;
    min-height: 100%;
    height: auto;
    display: flex;
    flex-flow: column;

    .body {
      display: flex;
      flex-direction: column;
      max-width: 500px;
      flex-grow: 1;
      .ck-editor__editable_inline {
        min-height: 150px;
      }

      .ckeditor-error-notification {
        font-size: 0.75rem;
        color: $lightRed;
        margin-top: -5px;
        margin-bottom: 20px;
        margin-left: 14px;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      }

      & > div {
        margin: 15px 0;
      }
    }

    .filter-data {
      display: flex;
      flex-wrap: wrap;

      & > div {
        margin: 15px 55px 15px 0;
        width: 270px;

        .icon-wrapper {
          position: relative;
          top: -27px;
          left: 220px;
          color: rgba(0, 0, 0, 0.54);
          z-index: 1;
          font-size: 10px;
          width: 20px;

          & svg:hover {
            background-color: rgba(0, 0, 0, 0.09);
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }
    }

    .schedule {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      //flex-grow: 1;
      margin: 40px 0;

      .MuiFormControl-root:first-child {
        .MuiInput-root {
          width: 270px;
        }
      }

      & > div {
        margin: 15px 15px 15px 0;
        width: 270px;
        display: flex;

        & > div {
          width: calc(100% - 40px);

          .icon-wrapper {
            position: relative;
            top: -27px;
            left: 180px;
            color: rgba(0, 0, 0, 0.54);
            z-index: 1;
            font-size: 10px;
            width: 20px;

            & svg:hover {
              background-color: rgba(0, 0, 0, 0.09);
              border-radius: 50%;
              cursor: pointer;
            }
          }
        }

        & > span {
          display: inline-block;
          text-align: center;
          width: 25px;
          margin-right: 15px;
          padding-top: 20px;
          font-size: 1rem;
          font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        }
      }

      .clear-button {
        background-color: #ff4d4f !important;
        color: #ffffff;
        width: 100px;
        height: 40px;
        margin-top: 25px;
      }
    }

    .card-actions {
      button {
        margin-right: 10px;
      }
    }
  }

  .card {
    width: 100%;
    margin-top: 15px;

    .card-actions {
      display: flex;
      justify-content: space-between;

      .cancel-button {
        margin-left: 15px;
      }

      .delete {
        background-color: $pink;
      }
    }
  }
}

.dates {
  .MuiPaper-root .MuiList-root {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 230px;

    .MuiMenuItem-root {
      width: 60px;
      text-align: center;
    }
  }
}
