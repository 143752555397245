.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .text-field {
    width: 90%;
    min-height: 70px;
  }

  .input {
    background-color: white;
  }

  button {
    width: 90%;
  }
}
