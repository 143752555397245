.crs-datepicker-container {
  display: flex;
  flex-direction: column;
  min-height: 56px;
  max-height: 78px;
  position: relative;

  .label {
    position: absolute;
    top: 20px;
    left: 10px;
    transition: top 0.2s;

    &.has-value {
      top: 2px;
    }

    &.has-error {
      color: $lightRed;
    }
  }

  .crs-date-picker {
    padding: 20px 11px 6.5px;
    width: 100%;
    height: 56px;
    background-color: $whisper;
    border: none;
    outline: none;
    box-shadow: none;
    position: relative;
    transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    &:hover {
      background-color: $whisperHover;
    }

    &:after {
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      position: absolute;
      transform: scaleX(0);
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      border-bottom: 2px solid #3f51b5;
      pointer-events: none;
    }

    &:before {
      left: 0;
      right: 0;
      bottom: 0;
      content: '\00a0';
      position: absolute;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      pointer-events: none;
    }

    &.ant-picker-focused {
      &:before {
        border-bottom: 1px solid rgba(0, 0, 0, 0.87);
      }
      &:after {
        transform: scaleX(1);
      }
    }
  }

  .error-message {
    color: $lightRed;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    margin-left: 14px;
    margin-right: 14px;
  }
}
