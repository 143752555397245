@font-face {
  font-family: $fontComfortaaRegular;
  src: url(../../assets/font/comfortaa/Comfortaa-Regular.ttf);
}

@font-face {
  font-family: $fontComfortaaLight;
  src: url(../../assets/font/comfortaa/Comfortaa-Light.ttf);
}

@font-face {
  font-family: $fontComfortaaBold;
  src: url(../../assets/font/comfortaa/Comfortaa-Bold.ttf);
}
