.default_search_input {
  width: 270px;
}

@media print { 
  .no-print  { display: none;}
  .print  { display: block;}
}

.test-title {
  color: red;
}