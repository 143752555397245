.credit-card {
  //
  //.info {
  //  display: flex;
  //  align-items: center;
  //
  //  input {
  //    height: 30px;
  //    padding: 5px 10px;
  //    width: 200px;
  //    overflow: hidden;
  //    white-space: nowrap;
  //    text-overflow: ellipsis;
  //  }
  //
  //  .label {
  //    width: 220px;
  //    font-family: $fontComfortaaBold;
  //    font-weight: 400;
  //    line-height: 1;
  //    font-size: 16px;
  //    letter-spacing: 0.00938em;
  //  }
  //
  //  &.couple {
  //    .info {
  //      .label {
  //        width: 95px;
  //      }
  //
  //      input {
  //        width: 85px;
  //        min-width: 85px;
  //      }
  //
  //      + .info {
  //        margin-top: 0;
  //        margin-left: 20px;
  //      }
  //    }
  //  }
  //
  //  + .info {
  //    margin-top: 15px;
  //  }
  //}

  .crs-input {
    width: 100%;

    input {
      height: 30px;
      padding: 5px 10px;
    }

    + .crs-input {
      margin-top: 20px;
    }
  }

  .info {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .crs-input {
      width: 48%;

      + .crs-input {
        margin-top: 0;
      }
    }
  }
}
