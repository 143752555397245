.list-settings {
  height: 100%;

  .settings-form {
    width: 100%;
    min-height: 100%;
    height: auto;

    .divider {
      margin: 15px 0;

      &.secondary {
        margin: 10px 0;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .is-public-switcher {
        width: max-content;
        margin-top: 10px;
      }

      .text-field {
        max-width: 500px;

        + .text-field {
          margin-top: 15px;
        }
      }

      .actions {
        margin-top: 15px;
      }

      .crs-uploader {
        max-width: 500px;
        margin-top: 15px;
      }

      .add-service-button {
        margin-top: 15px;
        width: 40px;
        height: 40px;
        display: flex;
        align-self: flex-end;
        border: 1px solid $primary;

        svg {
          font-size: 22px;
        }
      }
    }

    .card {
      width: 100%;
      margin-top: 15px;

      .card-actions {
        display: flex;
        justify-content: space-between;

        .cancel-button {
          margin-left: 15px;
        }

        .delete {
          background-color: $pink;
        }
      }
    }
  }
}
