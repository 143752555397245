.dashboard {
  .datePicker-container {
    margin: 30px 0 50px;
  }

  .charts-container {
    .MuiList-root {
      display: flex !important;
      flex-wrap: wrap !important;

      & > li {
        max-width: 50% !important;

        .MuiTypography-root {
          font-size: 12px !important;
        }
      }
    }
  }
}
