.create-category,
.edit-category {
  height: 100%;

  .create-category-form,
  .edit-category-form {
    width: 100%;
    min-height: 100%;
    height: auto;

    .category-header {
      display: flex;
      align-items: center;
      min-height: 68px;

      .icon-wrapper {
        margin-left: 20px;

        input {
          display: none;
        }

        .MuiIconButton-root {
          padding: 0;

          .icon-upload-label {
            cursor: pointer;
            width: fit-content;
          }

          .category-icon {
            height: 60px;
            width: 60px;
            border-radius: 50%;
            object-fit: cover;
          }

          .default-icon {
            vertical-align: middle !important;
          }
        }
      }
    }

    .divider {
      margin: 15px 0;
    }

    .body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .text-field {
        max-width: 500px;

        + .text-field {
          margin-top: 15px;
        }
      }

      .child-categories,
      .parent-category,
      .actions,
      .crs-table-container {
        margin-top: 15px;
      }

      .parent-category {
        display: flex;
        max-width: 500px;
        justify-content: space-between;
        align-items: center;

        .attached-category-actions {
          button {
            padding: 4px;

            + button {
              margin-left: 10px;
            }
          }
        }
      }

      .child-categories {
        .crs-table-container {
          margin-top: 0;

          .crs-table {
            .crs-table-body {
              .crs-table-row {
                &.services {
                  background-color: $gray;

                  &:hover {
                    background-color: $ghostWhite;
                  }
                }

                .crs-table-cell {
                  padding: 8px;
                }
              }
            }
          }
        }
      }
    }

    .card {
      width: 100%;
      margin-top: 15px;

      .card-actions {
        display: flex;
        justify-content: space-between;

        .cancel-button {
          margin-left: 15px;
        }

        .delete {
          background-color: $pink;
        }
      }
    }
  }
}

.list-categories {
  .crs-table-container {
    .crs-table {
      .crs-table-body {
        .crs-table-row {
          &.services {
            background-color: $gray;

            &:hover {
              background-color: $ghostWhite;
            }
          }
        }
      }
    }
  }
}
