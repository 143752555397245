.create-language {
  height: 100%;

  .create-language-title {
    padding-left: 16px !important;
  }

  .divider {
    margin: 15px 0;
  }

  .create-page {
    height: calc(100% - 64px);

    div {
      height: 100%;

      div {
        height: 100%;

        .simple-form {
          display: flex;
          flex-direction: column;
          min-height: 100%;
          height: auto;

          .MuiCardContent-root {
            flex-grow: 1;
          }

          .MuiToolbar-root {
            .cancel-button {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
