.view-role-permission,
.create-role-permission,
.edit-role-permission {
  height: 100%;
  display: flex;
  flex-direction: column;

  .divider {
    margin: 15px 0;
  }

  .role-info {
    flex-grow: 1;

    .permissions-table {
      margin-top: 15px;

      th,
      td {
        &::first-letter {
          text-transform: capitalize;
        }
      }

      td {
        .actions {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .permissions-action {
    .card {
      width: 100%;
      margin-top: 15px;

      .card-actions {
        display: flex;
        justify-content: space-between;

        .cancel-button {
          margin-left: 15px;
        }
      }
    }
  }
}
