.list-user {
  .status-custom-select {
    width: 200px;
  }

  .list-user-table {
    overflow-x: auto;

    .user-age {
      cursor: default;
    }

    .tooltip {
      white-space: pre-line;
    }

    .MuiTypography-root {
      .action-button {
        display: flex;
        justify-content: space-between;
      }
    }

    .MuiTypography-root {
      .avatar-picture {
        height: 40px;
        width: 40px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .MuiTableCell-head {
      text-align: center;
    }

    .buttons-container {
      display: flex;
      flex-wrap: nowrap;

      .show-button,
      .edit-button,
      .delete-button,
      .verify-button {
        margin-right: 5px;
        // width: 155px;
      }
    }
  }
}

.MuiButton-startIcon.MuiButton-iconSizeSmall {
  margin: 0;
}
.MuiButton-startIcon.MuiButton-iconSizeMedium {
  margin: 0;
}

.list-user-dialog {
  .crs-dialog-paper {
    width: 40%;
    max-width: unset;
  }

  .crs-dialog-content {
    height: 0;
    border: none;
  }

  .crs-dialog-title {
    padding: 10px 30px;
  }
}

.crc-list-resend-user-dialog {
  .crs-dialog-paper {
    width: 40%;
    max-width: unset;
  }

  .crs-dialog-content {
    height: 150px;
    overflow: hidden;
    border: none;
    display: flex;
    flex-direction: column;

    .MuiFormControl-root {
      margin-bottom: 20px !important;
      width: 350px;
    }
  }

  .crs-dialog-title {
    padding: 10px 30px;
  }
}

.MuiTableCell-sizeSmall {
  &:last-child {
    position: -webkit-sticky;
    position: sticky;
    right: 0;
    z-index: 100;
    background-color: #ffffff;
  }
}
.MuiTablePagination-root {
  &:last-child {
    display: block;
    float: right;
    position: -webkit-sticky;
    position: sticky;
    right: 0;
  }
}
