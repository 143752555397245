.profile {
  width: 80%;
  margin: 50px auto 20px;
  display: flex;
  justify-content: space-between;

  .profile-data {
    display: flex;
    flex-direction: column;
    width: 40%;

    .MuiFormControl-root {
      margin-bottom: 40px !important;
    }
  }
}

.profile-save-button {
  width: 80%;
  margin: 50px auto;

  button + button {
    margin-left: 15px;
  }
}
