.edit-user {
  width: 80%;
  margin: 5px auto;
  height: 100%;

  .edit-user-form {
    min-height: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

    .wrapper {
      flex-grow: 1;
      .divider {
        margin: 15px 0;

        &.secondary {
          margin: 10px 0;
        }
      }
      .uploader-container {
        display: flex;
        align-items: center;

        .profile-image-upload-label {
          display: flex;
          width: fit-content;
          cursor: pointer;
        }

        .profile-picture {
          height: 150px;
          width: 150px;
          border-radius: 50%;
          object-fit: cover;
        }

        #imageUploader {
          display: none;
        }

        .image-save-button {
          margin-top: 10px;
          width: 100px;
        }
      }

      .edit-user-data-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 50px;
        flex-grow: 1;

        .edit-user-data {
          display: flex;
          flex-direction: column;
          width: 350px;
          margin-bottom: 10px;

          .MuiFormControl-root {
            margin-bottom: 15px !important;

            .MuiInput-formControl {
              margin-top: 16px !important;
              color: rgba(0, 0, 0, 0.87) !important;
            }
          }
        }
      }

      .container {
        display: flex;
        justify-content: space-between;

        .payment-methods {
          .attached-cards {
            .credit-card-info {
              display: flex;
              align-items: center;

              .card-info {
                width: 300px;
              }

              .icon {
                font-weight: bold;
                cursor: pointer;
                padding: 0;

                &.view {
                  color: $blue;
                }

                &.delete {
                  color: $red;
                }

                + .icon {
                  margin-left: 15px;
                }
              }
            }
          }
        }

        .buttons-container {
          .reset-block-buttons-container {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;

            .reset-password-button {
              margin-bottom: 20px;
            }
          }
        }
      }

      .patient-data {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .add-patient-button {
          margin-top: 15px;
          width: 40px;
          height: 40px;
          display: flex;
          align-self: flex-end;
          border: 1px solid $primary;

          svg {
            font-size: 22px;
          }
        }

        .avatar-picture {
          height: 40px;
          width: 40px;

          img {
            width: 100%;
            min-height: 100%;
          }
        }
      }
    }
    .card {
      width: 100%;

      .card-actions {
        display: flex;
        justify-content: space-between;

        .cancel-button {
          margin-left: 15px;
        }
      }
    }
  }
}

.crc-block-user-dialog {
  .crs-dialog-paper {
    width: 40%;
    max-width: unset;
  }

  .crs-dialog-content {
    height: 30px;
    border: none;
  }

  .crs-dialog-title {
    padding: 10px 30px;
  }
}

.crc-reset-user-dialog {
  .crs-dialog-paper {
    width: 40%;
    max-width: unset;
  }

  .crs-dialog-content {
    height: auto;
    border: none;
    display: flex;
    flex-direction: column;

    .MuiFormControl-root {
      margin-bottom: 20px !important;
      width: 350px;
    }
  }

  .crs-dialog-title {
    padding: 10px 30px;
  }
}

.crc-patient-dialog {
  .crs-dialog-paper {
    width: 40%;
    max-width: unset;
  }

  .crs-dialog-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70% !important;
    border: none;
  }
}

.credit-card-dialog {
  .crs-dialog-paper {
    width: 470px;

    .crs-dialog-title {
      padding: 3px 15px;
    }

    .crs-dialog-content {
      height: auto;
    }
  }
}
