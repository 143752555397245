.edit-language-container {
  height: 100%;

  .edit-language-form {
    min-height: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

    .wrapper {
      flex-grow: 1;

      .edit-language-title {
        padding-left: 16px !important;
      }

      .divider {
        margin: 15px 0;
      }

      .container {
        display: flex;
        flex-direction: column;
        height: 500px;
        justify-content: space-between;
        padding: 16px;
      }

      .uploaded-font-name {
        font-size: 17px;
      }

      .uploader-container {
        display: flex;

        .file-upload-label {
          cursor: pointer;
          display: flex;
          width: fit-content;

          .upload-icon {
            font-size: 40px;
          }
        }

        .font-upload-input {
          display: none;
        }

        .file-save-button {
          margin-left: 30px;
        }
      }

      .edit-language {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 256px;
        height: 309px;
      }

      .MuiSwitch-colorSecondary.Mui-checked {
        color: #3f51b5;
      }

      .MuiSwitch-track {
        background-color: #3f51b5 !important;
      }
    }

    .card {
      width: 100%;
      margin-top: 15px;

      .card-actions {
        display: flex;
        justify-content: space-between;

        .cancel-button {
          margin-left: 10px;
        }

        .delete {
          background-color: $pink;
        }
      }
    }
  }
}
