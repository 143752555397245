.sidebar-menu-container {
  padding: 20px 0;

  .menu-item {
    display: flex;
    align-items: center;

    .menu-label {
      padding-top: 5px;
    }
    &.selected {
      background-color: rgba(255, 255, 255, 0.25);
    }
  }
}
