.layout {
  display: flex;
  flex-direction: column;
  z-index: 1;
  min-height: 100vh;
  height: auto;

  .app-frame {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    min-height: 100vh;
    height: auto;

    .app-bar {
      background-color: $darkPurple;
      height: 50px;

      .RaLoadingIndicator-loadedIcon-11 {
        display: none;
        height: 0;
        visibility: hidden;
      }
    }

    .main {
      display: flex;
      margin-top: 50px;
      height: calc(100vh - 50px);
      overflow: hidden;

      .sidebar {
        background-color: $darkPurple;

        svg {
          fill: $ghostWhite;
        }

        a {
          color: $ghostWhite;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        padding: 30px 10px 10px 10px;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }
}
