.show-user {
  width: 80%;
  margin: 5px auto;
  height: 100%;

  .show-user-form {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: auto;

    .wrapper {
      flex-grow: 1;
      .divider {
        margin: 15px 0;

        &.secondary {
          margin: 10px 0;
        }
      }
      .profile-picture {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
      }

      .show-user-data-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 50px;

        .MuiFormControl-root {
          margin-bottom: 15px !important;
          color: red !important;
        }

        .MuiInputBase-root {
          color: rgba(0, 0, 0, 0.87) !important;
        }

        .show-user-data {
          display: flex;
          flex-direction: column;
          width: 350px;
          margin-bottom: 50px;
        }
      }

      .container {
        .payment-methods {
          .attached-cards {
            .credit-card-info {
              display: flex;
              align-items: center;

              .card-info {
                width: 300px;
              }

              .icon {
                font-weight: bold;
                cursor: pointer;
                padding: 0;

                &.view {
                  color: $blue;
                }

                &.delete {
                  color: $red;
                }

                + .icon {
                  margin-left: 15px;
                }
              }
            }
          }
        }

        .crs-table-container {
          margin-top: 20px;

          .avatar-picture {
            height: 40px;
            width: 40px;

            img {
              width: 100%;
            }
          }
        }
      }
    }

    .card {
      width: 100%;
      margin-top: 20px;

      .card-actions {
        display: flex;
        justify-content: space-between;

        .unBlock-buttom {
          margin-right: 20px;
        }
      }
    }
  }
}

.crs-dialog-unblock {
  .crs-dialog-paper {
    width: 40%;
    max-width: unset;
  }

  .crs-dialog-content {
    height: 30px;
    border: none;
  }

  .crs-dialog-title {
    padding: 10px 30px;
  }
}
