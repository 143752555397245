.crs-dialog {
  .crs-dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
  }

  .crs-dialog-content {
    height: 600px;
  }

  .crs-dialog-paper {
    width: 80%;
    max-width: unset;
  }

  .crs-dialog-actions {
    padding: 10px 20px;
  }
}
