.list-translations {
  .MuiFilledInput-root {
    margin-right: 15px !important;
  }

  .MuiFilledInput-underline.Mui-error:after {
    border-bottom-color: #3f51b5 !important;
  }

  .MuiFormLabel-root.Mui-error {
    color: #3f51b5 !important;
  }

  .language-custom-select {
    min-width: 300px;
  }

  .translations-table {
    margin-top: 80px;
    display: flex;
    flex-direction: column;

    td {
      .actions {
        display: flex;
        justify-content: center;
      }

      .MuiInput-underline:after {
        border-bottom: none !important;
      }

      .MuiInput-underline:before {
        border-bottom: none !important;
      }
    }
  }

  .add-service-button {
    margin-top: 15px;
    margin-left: auto;
    width: 40px;
    height: 40px;
    align-self: flex-end;
    border: 1px solid $primary;
    float: right;

    svg {
      font-size: 22px;
    }
  }
}

.create-translation-dialog,
.edit-translation-dialog {
  .crs-dialog-paper {
    width: 40%;
    max-width: unset;
  }

  .crs-dialog-content {
    height: 180px;
    overflow: hidden;
    border: none;
    display: flex;
    flex-direction: column;

    .MuiFormControl-root {
      margin-bottom: 20px !important;
      width: 350px;
    }
  }

  .crs-dialog-title {
    padding: 10px 30px;
  }

  .submit-user-button {
    margin-right: 20px !important;
    width: 80px;
  }
}

.edit-translation-dialog {
  .crs-dialog-content {
    min-height: 350px;
    max-height: 600px;
    height: unset;
  }
}
