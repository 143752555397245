body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

.hidden {
  display: none;
}
