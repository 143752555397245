.order-service,
.create-order,
.edit-order {
  height: 100%;

  .edit-order-service-form,
  .create-order-form,
  .edit-order-form {
    width: 100%;
    min-height: 100%;

    .divider {
      margin: 15px 0;

      &.secondary {
        margin: 10px 0;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .order-service-info,
      .order-patient,
      .order-info {
        display: flex;
        flex-direction: column;

        .users {
          margin-top: 15px;
        }
      }

      .order-service-info,
      .order-user {
        .list-item {
          min-width: 120px;
        }

        .href {
          cursor: pointer;
          color: $blue;
          flex: unset;
        }
      }

      .order-price {
        .list-item {
          min-width: 170px;
        }
      }

      .calculate-order-price {
        margin-top: 15px;
        max-width: 500px;
      }

      .text-field {
        max-width: 500px;

        + .text-field {
          margin-top: 15px;
        }
      }

      .order-patient-image {
        height: 40px;
        width: 40px;
        margin-top: 15px;
      }

      .actions {
        margin-top: 15px;
      }

      .attached-services {
        .crs-table-container {
          .crs-table {
            .crs-table-body {
              .crs-table-row {
                .crs-table-cell {
                  padding: 8px;

                  .redirect-button {
                    padding: 0;
                    background-color: transparent;
                    color: $blue;
                    text-transform: unset;

                    &:hover {
                      background-color: transparent;
                    }
                  }

                  .table-actions {
                    display: flex;
                    justify-content: space-around;

                    .icon {
                      padding: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .button-wrapper {
        display: flex;
        margin-top: 15px;

        .order-status-buttons {
          display: flex;
        }

        .order-status-button {
          min-width: 110px;
          margin-right: 15px;
        }
      }

      .attachments {
        max-width: 500px;
        width: 100%;

        .attachment {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .attachment-link {
            max-width: 450px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .icon {
            color: $red;
            font-weight: bold;
            cursor: pointer;
            padding: 0;

            &.view {
              color: $blue;
            }

            + .icon {
              margin-left: 15px;
            }
          }

          + .attachment {
            margin-top: 10px;
          }
        }
      }

      .crs-uploader {
        max-width: 500px;
        margin-top: 15px;
      }

      .change-patient-button,
      .add-bundle-button,
      .add-service-button {
        width: 40px;
        height: 40px;
        display: flex;
        align-self: flex-end !important;
        border: 1px solid $primary;

        svg {
          font-size: 22px;
        }
      }

      .change-patient-button {
        margin-top: 15px;
      }

      .change-patient-button {
        align-self: flex-start;
      }
    }

    .card {
      width: 100%;
      margin-top: 15px;

      .card-actions {
        display: flex;
        justify-content: space-between;

        .cancel-button {
          margin-left: 15px;
        }

        .delete {
          background-color: $pink;
        }
      }
    }
  }
}

.orders-list {
  .order-status-select {
    width: fit-content;
    min-width: 130px;
  }

  & > div {
    width: 100%;
    overflow: hidden;
  }

  .order-list-container {
    width: 100%;
    overflow: auto;
  }

  .phone {
    text-decoration: none;
  }

  .patient-age,
  .agents-indicator,
  .status-indicator,
  .service-indicator {
    cursor: default;
  }
}

.tooltip {
  white-space: pre-line;
}

.attachment-preview-dialog {
  .crs-dialog-content {
    max-height: 80%;
    height: auto;
  }

  .crs-dialog-paper {
    max-width: 80%;
    width: unset;
  }
}

.order-service,
.edit-order {
  .crs-table-container {
    .crs-table {
      .crs-table-body {
        .crs-table-row {
          .crs-table-cell {
            padding: 8px;
          }
        }
      }
    }
  }
}

.services-dialog,
.bundles-dialog {
  .filter {
    min-width: 35%;
  }

  .crs-table-container {
    margin-top: 15px;
  }
}

.order-services,
.order-bundles {
  display: flex;
  align-items: center;
  .add-service-button,
  .add-bundle-button {
    margin: 0 15px;
  }
}

.edit-order .edit-order-form .card {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0 14px 0 0 #ffffff;
  background-color: #ffffff;
}

@media print {
  body,
  html {
    padding: 0;
    margin: 0;
  }

  .no-print {
    display: none !important;
  }
  .do-print {
    background-color: #fff;
    display: block !important;

    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.test-title {
  color: red !important;
}

.do-print {
  .underline {
    border-top: 1px solid black;
    text-align: center;
    font-size: 12px;
  }

  .line {
    padding: 8px 0;
  }
}
