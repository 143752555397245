.show-payment {
  display: flex;
  flex-direction: column;

  .show-payment-form {
    .MuiCardContent-root {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }

    .show-payment-title {
      padding-left: 16px !important;
    }

    .divider {
      margin: 15px 0;
    }

    .ra-field {
      padding: 0 18px;
      background-color: aliceblue;
      height: 55px;
      border-radius: 8px;
      display: flex;

      > div {
        margin: 0;
        flex-direction: row;
        align-items: center;
        width: 100%;

        > label {
          min-width: fit-content;
        }

        > div {
          padding: 0 0 0 8px;
        }
      }
    }
  }

  .MuiCard-root {
    overflow: unset;
    margin-top: 20px;
  }

  .divider.secondary {
    margin: 10px 0;
  }

  .MuiTypography-h6 {
    margin-top: 30px;
  }

  .crs-table-body .crs-table-row .crs-table-cell {
    padding: 12px 8px !important;
  }

  .cancel-button {
    margin-top: 30px;
  }
}
