.create-user-notification,
.edit-user-notification {
  height: 100%;

  .create-user-notification-form,
  .edit-user-notification-form {
    width: 100%;
    min-height: 100%;
    height: auto;

    .user-notification-header {
      display: flex;
      align-items: center;
      min-height: 68px;

      .icon-wrapper {
        margin-left: 20px;

        input {
          display: none;
        }

        .MuiIconButton-root {
          padding: 0;

          .icon-upload-label {
            cursor: pointer;
            width: fit-content;
          }

          .default-icon {
            vertical-align: middle !important;
          }
        }
      }
    }

    .divider {
      margin: 15px 0;
    }

    .body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .text-field {
        max-width: 500px;

        + .text-field {
          margin-top: 15px;
        }
      }

      .actions,
      .crs-table-container {
        margin-top: 15px;
      }
    }

    .card {
      width: 100%;
      margin-top: 15px;

      .card-actions {
        display: flex;
        justify-content: space-between;

        .cancel-button {
          margin-left: 15px;
        }

        .delete {
          background-color: $pink;
        }
      }
    }
  }
}
